export type AppRoutes = {
  resetPassword1: string;
  resetPassword2: string;
  check2fa: string;
  error: string;
};

const resetPassword1Path = "/resetPassword1";
const resetPassword2Path = "/resetPassword2";
const check2faPath = "/check";
const errorPath = "/error";

const Routes: AppRoutes = {
  resetPassword1: `${resetPassword1Path}`,
  resetPassword2: `${resetPassword2Path}`,
  check2fa: `${check2faPath}`,
  error: `${errorPath}`,
};

export default Routes;
