class Utils {
  static isValidEmail(email: string) {
    return /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
      email
    );
  }

  static validateEmail(email: string = ""): string {
    if (!email.length) return "E-mail is required";

    if (!this.isValidEmail(email)) return "E-mail is not valid";

    return "";
  }

  static validatePassword(
    password: string | undefined,
    validateSecure: boolean = true
  ): string {
    /* var minMaxLength = /^[\S]{8,30}$/,
      allowed = /^[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/,
      notAllowed = /[^a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/; */

    if (!password || !password.trim()) return "Password is required";

    return "";
  }
}
export default Utils;
