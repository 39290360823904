import { useContext } from "react";
import { useNavigate } from "react-router";
import { EmailContext } from "../../../utils/contexts/EmailContext";
import Card from "../../Presentational/Card";
import style from "../style.module.scss";
import cn from "classnames";

const ResetPasswordStep2 = () => {
  const { email } = useContext(EmailContext);
  const navigate = useNavigate();

  const handleSendAgain = () => {};

  const handleBackToLogin = () => {
    navigate("/");
  };

  return (
    <Card>
      <div className={style.formCantainer}>
        <h1 className={cn("title", "center-txt")}>Thank you!</h1>
        <div className="center-txt">
          An email containing a link to reset your password has been sent to
          <strong>&nbsp;{email}.</strong>&nbsp; Please follow the instructions.
        </div>
        <div className={style.btnContainer}>
          <button className="btn-link" onClick={handleSendAgain}>
            Send again
          </button>

          <button className={style.btn} onClick={handleBackToLogin}>
            Back to login
          </button>
        </div>
      </div>
    </Card>
  );
};

export default ResetPasswordStep2;
