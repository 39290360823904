import * as Types from "./types";
import * as Enums from "./enums";
import { TFBO_URL } from "./constants/BACKEND_URL";

type RequestParams = {
  payload: object;
  session_id?: string;
  token?: string;
  challengeResponse?: string;
};
export enum Authorize {
  Yes,
  No,
}

class ApiError extends Error {
  validationErrors: string = "";

  constructor(message?: string) {
    // 'Error' breaks prototype chain here
    super(message);

    // restore prototype chain
    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      (this as any).__proto__ = actualProto;
    }
  }
}

class Api {
  private token: string;
  private session: string;

  constructor() {
    this.session = localStorage[Enums.__STORAGE.session];
    this.token = localStorage[Enums.__STORAGE.token];
  }
  _request<T>(
    method: string,
    request?: string | FormData,
    contentType: string = "application/json"
  ) {
    const headers: HeadersInit = { "Content-Type": contentType };

    const config: RequestInit = {
      method,
      headers,
    };

    if (request) config.body = request;

    const response = fetch(TFBO_URL, config);

    return response
      .then((response) => {
        if (response.status === 401) {
          throw Error("Unauthorized");
        }

        if (response.status >= 402) {
          let errorMessage = "Server is unavailable. Please try later.";
          throw Error(errorMessage);
        }

        return response;
      })
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);

        return response;
      })
      .then((response) => {
        const contentType = response.headers.get("content-type");

        if (contentType) {
          if (contentType.startsWith("application/json"))
            return response.json();
          else if (contentType.startsWith("application/octet-stream"))
            return response.blob();
          else if (contentType.startsWith("image/png")) return response.blob();
        }

        throw Error('Wrong content-type: "' + contentType + '"');
      })
      .then((response) => {
        if (response.error) {
          let errorMessage;

          if (response.message && response.message.length)
            errorMessage = response.message;
          else if (response.error.code && response.error.description)
            errorMessage = response.error.description;

          const error = new ApiError(errorMessage);

          error.validationErrors = response.validationErrors;

          throw error;
        }
        return response as Types.APIResponse<T>;
      });
  }

  _createParams(
    params: Types.RequestBody,
    auth: Authorize,
    challenge?: string
  ): string {
    let result = this._createParamsObject(auth);
    result.payload = [params];
    if (challenge) result = { ...result, challengeResponse: challenge };
    return JSON.stringify(result);
  }

  _createParamsObject(auth: Authorize): RequestParams {
    if (auth === Authorize.Yes) {
      if (!this.session || !this.token) throw new Error("no session");
    }
    let result: RequestParams = { payload: [] };
    if (auth === Authorize.Yes)
      result = { ...result, session_id: this.session, token: this.token };
    return result;
  }
  _post<T>(request?: string, contentType = "application/json") {
    return this._request<T>("POST", request, contentType);
  }

  resetPasswordStepOne = (
    data: Pick<Types.ResetPassword, "email_id" | "response">
  ) => {
    const params = this._createParams(
      {
        module: "authentication",
        action: "forgot_password_web",
        parameters: data,
      },
      Authorize.No
    );

    return this._post<Types.ReCaptchaResponse>(params);
  };

  resetPasswordStepTwo = (
    data: Pick<
      Types.ResetPassword,
      "password" | "password_reset_token" | "response"
    >
  ) => {
    const params = this._createParams(
      {
        module: "authentication",
        action: "forgot_password_web",
        parameters: data,
      },
      Authorize.No
    );

    return this._post<Types.ReCaptchaResponse>(params);
  };
}
let api = new Api();

export default api;
