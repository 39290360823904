import Card from "../Presentational/Card";
import style from "./style.module.scss";

const ErrorPage = () => {
  return (
    <Card className={style.error}>
      <p className={style.errorTxt}>There is an unknown error...!</p>
    </Card>
  );
};

export default ErrorPage;
