import { Form, Formik } from "formik";
import { useCallback, useContext, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EmailContext } from "../../utils/contexts/EmailContext";
import Card from "../Presentational/Card";
import InputField from "../Presentational/InputField";
import style from "./style.module.scss";
import { BACKEND_URL } from "../../utils/constants/BACKEND_URL";
import { LoginResultContext } from "../../utils/contexts/LoginResultContext";
import { LoginResult } from "../../utils/types";

interface FormData {
  code: string;
}

const initialFormData: FormData = {
  code: "",
};

const Check2FA = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { email } = useContext(EmailContext);
  const { loginResult } = useContext(LoginResultContext);
  const initialValues = useMemo(() => {
    return { ...initialFormData };
  }, []);

  const urlParams = useMemo(() => {
    return searchParams.toString();
  }, [searchParams]);

  const handleFormSubmit = async (values: FormData, { setErrors }: any) => {
    let data = {
      email: email,
      code: values.code,
    };

    try {
      const response: Response = await fetch(
        `${BACKEND_URL}/sso/tfa${urlParams ? "?" + urlParams : ""}`,
        {
          method: "POST",
          headers: {
            Authorization: `${loginResult?.tokens.accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const resultJson: LoginResult = await response.json();

      if (resultJson.status === "NOT_OK") {
        setErrors({ code: "Invalid code!" });
      }

      if (resultJson?.redirectURI) {
        window.location.replace(resultJson.redirectURI);
      }
    } catch (error) {}
  };

  const handleBack = () => {
    navigate(`/?${urlParams}`);
  };

  const validateCode = useCallback((code: string) => {
    if (!code.length) return "Code is required";
    return "";
  }, []);

  return (
    <Card>
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({ errors, touched }) => (
          <Form className={style.formCantainer}>
            <h1 className={"title"}>Hello</h1>
            <p>
              Please enter your security code found on Google Authenticator.
            </p>
            <div className={style.fieldContainer}>
              <InputField
                name="code"
                placeholder="Security Code"
                validate={validateCode}
                caption="Security Code"
                error={errors.code}
              />
            </div>

            <div className={style.btnContainer}>
              <button className="btn-link" onClick={handleBack} type="button">
                Back
              </button>

              <button className={style.btn} type="submit">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default Check2FA;
