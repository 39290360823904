import style from "./style.module.scss";
import thinkMarketsLogo from "../../../resources/images/tm-logo.svg";
import thinkMarketsLogoMobile from "../../../resources/images/tm-logo-mobile.svg";
import useWindowDimensions from "../../../utils/hooks";
import cn from "classnames";

interface LoginCardProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

const Card = ({ children, className }: LoginCardProps) => {
  const { width } = useWindowDimensions();

  return (
    <div className={cn("card", className)}>
      <div className={style.cardHeader}>
        <img
          src={width > 450 ? thinkMarketsLogo : thinkMarketsLogoMobile}
          alt="think markets logo"
        />
      </div>
      <div className={style.layout}>{children}</div>
    </div>
  );
};

export default Card;
