import { Route, Routes } from "react-router";
import LoginPage from "../LoginPage";
import style from "./style.module.scss";
import appRoutes from "../../utils/routes";
import ResetPasswordStep1 from "../ResetPassword/ResetPasswordStep1";
import ResetPasswordStep2 from "../ResetPassword/ResetPasswordStep2";
import Check2FA from "../Check2FA";
import ErrorPage from "../ErrorPage";

const Layout = () => {
  return (
    <div className={style.app}>
      <Routes>
        <Route path={"/"} element={<LoginPage />} />

        <Route
          path={appRoutes.resetPassword1}
          element={<ResetPasswordStep1 />}
        />
        <Route
          path={appRoutes.resetPassword2}
          element={<ResetPasswordStep2 />}
        />
        <Route path={appRoutes.check2fa} element={<Check2FA />} />
        <Route path={appRoutes.error} element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default Layout;
