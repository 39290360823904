import style from "./style.module.scss";

import { Form, Formik } from "formik";
import { useCallback, useContext, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import appRoutes from "../../utils/routes";
import Card from "../Presentational/Card";
import Utils from "../../utils/utils";
import InputField from "../Presentational/InputField";
import PasswordInputField from "../Presentational/PasswordInputField";
import { LoginResult } from "../../utils/types";
import { EmailContext } from "../../utils/contexts/EmailContext";
import { BACKEND_URL } from "../../utils/constants/BACKEND_URL";
import { LoginResultContext } from "../../utils/contexts/LoginResultContext";

interface FormData {
  email: string;
  password: string;
}

const initialFormData: FormData = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const initialValues = useMemo(() => {
    return { ...initialFormData };
  }, []);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleSetEmail } = useContext(EmailContext);
  const { handleSetLoginResult } = useContext(LoginResultContext);

  const urlParams = useMemo(() => {
    return searchParams.toString();
  }, [searchParams]);

  const handleFormSubmit = async (values: FormData, { setErrors }: any) => {
    handleSetEmail(values.email);
    let data: FormData = {
      email: values.email,
      password: values.password,
    };

    try {
      const response: Response = await fetch(
        `${BACKEND_URL}/sso/login${urlParams ? "?" + urlParams : ""}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const resultJson: LoginResult = await response.json();

      if (resultJson?.redirectURI) {
        window.location.replace(resultJson.redirectURI);
      }

      handleSetLoginResult(resultJson);

      if (resultJson.status === "TFA_REQUIRED") {
        navigate({ pathname: appRoutes.check2fa, search: `?${urlParams}` });
      }

      if (resultJson.status === "LOCKED_OUT") {
        alert("Your account is locked");
      }

      if (resultJson.code === "ASE-001") {
        setErrors({
          email: " ",
          password:
            "Invalid Credentials: Please check your email and password!",
        });
      }
      if (resultJson.code === "ASE-003") {
        setErrors({
          email: " ",
          password: "Invalid account! You don't have a ThinkCopy account.",
        });
      }
    } catch (error) {}
  };

  const validateEmail = useCallback((email: string) => {
    const error = Utils.validateEmail(email);
    return error;
  }, []);

  return (
    <Card>
      <h1 className={"title"}>Log In</h1>
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({ errors, touched }) => (
          <Form>
            <div className={style.fieldContainer}>
              <InputField
                caption="Email"
                name="email"
                placeholder="Enter your email"
                type="email"
                validate={validateEmail}
                error={errors.email}
                touched={touched.email}
                autoComplete="email"
              />

              <PasswordInputField error={errors.password} />
            </div>
            <div className={style.btnContainer}>
              <button className={style.btn} type="submit">
                Log in
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default LoginPage;
