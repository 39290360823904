import React from "react";
import { useState } from "react";
import { LoginResult } from "../../types";

interface LoginResultContextProps {
  loginResult: LoginResult | undefined;
  handleSetLoginResult: (loginResult: LoginResult) => void;
}

export const LoginResultContext = React.createContext<LoginResultContextProps>(
  {} as LoginResultContextProps
);

interface LoginResultProviderProps {
  children: JSX.Element | JSX.Element[];
}

const LoginResultProvider = ({ children }: LoginResultProviderProps) => {
  const [loginResult, setLoginResult] = useState<LoginResult>();

  const handleSetLoginResult = (loginResult: LoginResult) => {
    setLoginResult(loginResult);
  };

  return (
    <LoginResultContext.Provider value={{ loginResult, handleSetLoginResult }}>
      {children}
    </LoginResultContext.Provider>
  );
};

export default LoginResultProvider;
