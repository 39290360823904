import InputField from "../InputField";
import style from "./style.module.scss";
import eyeIcon from "../../../resources/images/eye-icon.svg";
import eyeOffLineIcon from "../../../resources/images/eye-off-line-icon.svg";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import appRoutes from "../../../utils/routes";
import Utils from "../../../utils/utils";
interface PasswordInputFieldProps {
  error?: string;
}

const PasswordInputField = ({ error }: PasswordInputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validatePassword = useCallback((password: string) => {
    return Utils.validatePassword(password);
  }, []);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgottenPassword = () => {
    navigate(appRoutes.resetPassword1);
  };
  return (
    <>
      <div className={style.passwordContainer}>
        <p>Password</p>
        <div
          className={style.forgottenPassword}
          onClick={handleForgottenPassword}
        >
          Forgot Password?
        </div>
      </div>
      <div className={style.passwordFieldContainer}>
        <div className={style.eyeIconContainer} onClick={handleShowPassword}>
          <img src={showPassword ? eyeIcon : eyeOffLineIcon} alt="eye Icon" />
        </div>

        <InputField
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="Enter you password"
          autoComplete="current-password"
          validate={validatePassword}
          error={error}
          isPasswordField
        />
      </div>

      {error ? <div className={"error"}>{error}</div> : null}
    </>
  );
};

export default PasswordInputField;
