import { BrowserRouter } from "react-router-dom";
import "../src/css/index.scss";
import Layout from "./components/Layout";
import EmailProvider from "./utils/contexts/EmailContext";
import LoginResultProvider from "./utils/contexts/LoginResultContext";
function App() {
  return (
    <BrowserRouter>
      <EmailProvider>
        <LoginResultProvider>
          <Layout />
        </LoginResultProvider>
      </EmailProvider>
    </BrowserRouter>
  );
}

export default App;
