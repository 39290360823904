import React from "react";
import { useState } from "react";

interface EmailContextProps {
  email: string;
  handleSetEmail: (email: string) => void;
}

export const EmailContext = React.createContext<EmailContextProps>(
  {} as EmailContextProps
);

interface EmailProviderProps {
  children: JSX.Element | JSX.Element[];
}

const EmailProvider = ({ children }: EmailProviderProps) => {
  const [email, setEmail] = useState("");

  const handleSetEmail = (email: string) => {
    setEmail(email);
  };

  return (
    <EmailContext.Provider value={{ email, handleSetEmail }}>
      {children}
    </EmailContext.Provider>
  );
};

export default EmailProvider;
