import { Field } from "formik";
import { HTMLInputTypeAttribute } from "react";
import style from "./style.module.scss";
import cn from "classnames";

interface InputFieldProps {
  caption?: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  placeholder: string;
  validate: (value: string) => string;
  error?: string;
  touched?: boolean;
  isPasswordField?: boolean;
  autoComplete?: string;
}

const InputField = ({
  caption,
  name,
  type,
  placeholder,
  validate,
  error,
  touched,
  isPasswordField,
  autoComplete,
}: InputFieldProps) => {
  return (
    <>
      {caption && <p>{caption}</p>}
      <Field
        className={cn(
          style.field,
          error ? "error-field-border" : !isPasswordField && "margin-bottom"
        )}
        name={name}
        type={type}
        placeholder={placeholder}
        validate={validate}
        autoComplete={autoComplete}
        form="novalidatedform"
      />
      {error && !isPasswordField ? (
        <div className={"error"}>{error}</div>
      ) : null}
    </>
  );
};

export default InputField;
