import { Field, Form, Formik } from "formik";
import { useCallback, useContext, useMemo } from "react";
import Card from "../../Presentational/Card";
import style from "../style.module.scss";
import { useNavigate } from "react-router-dom";
import appRoutes from "../../../utils/routes";
import { EmailContext } from "../../../utils/contexts/EmailContext";
import api from "../../../utils/api";
import InputField from "../../Presentational/InputField";
import Utils from "../../../utils/utils";

interface FormData {
  email: string;
}

const initialFormData: FormData = {
  email: "",
};

const ResetPasswordStep1 = () => {
  const navigate = useNavigate();
  const { handleSetEmail } = useContext(EmailContext);

  const initialValues = useMemo(() => {
    return { ...initialFormData };
  }, []);

  const handleFormSubmit = async (values: FormData) => {
    const token = "";
    const response = await api.resetPasswordStepOne({
      email_id: values.email,
      response: token,
    });

    handleSetEmail(values.email);
    navigate(appRoutes.resetPassword2);
  };

  const handleCancel = () => {
    navigate("/");
  };

  const validateEmail = useCallback((email: string) => {
    const error = Utils.validateEmail(email);
    return error;
  }, []);

  return (
    <Card>
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({ errors, touched }) => (
          <Form className={style.formCantainer}>
            <h1 className={"title"}>Forgot your password?</h1>
            <div className={style.fieldContainer}>
              <InputField
                caption="Email"
                name="email"
                placeholder="Enter your email"
                type="email"
                validate={validateEmail}
                error={errors.email}
                touched={touched.email}
              />
            </div>

            <div className={style.btnContainer}>
              <button className="btn-link" onClick={handleCancel} type="button">
                Cancel
              </button>

              <button className={style.btn} type="submit">
                Reset Password
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default ResetPasswordStep1;
